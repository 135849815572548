import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { MortgageGuideRoutes } from "GenericMortgageGuides";
import Footer from "./components/FooterNew/index";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const location = window.location.pathname;
const baseName = process.env.REACT_APP_BASENAME;

let basePath = baseName;
if (baseName === "/streamline-va") {
  basePath = basePath + "/";
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
        {location === baseName || location === "/streamline-va/" ? <App /> : ""}
    {location !== baseName && location !== "/streamline-va/" ?
      <MortgageGuideRoutes  homePath={basePath} showHeader={true} showFooter={true} FooterComponent={Footer} /> : ""}
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
